<template>
	<div class="selectBox">
		<div style="width: 150px;" class="selectItem">
			<CitySelect :ctiy='ctiy' @selectArea='selectArea' ref="CitySelect"></CitySelect>
		</div>
		<div class="selectItem">
			<DatePicker size='large' transfer :options="options" @on-change='selectTime' v-model="time"
				:editable='false' type="month" placeholder="选择月份">
			</DatePicker>
		</div>
		<div class="selectItem">
			<Select size='large' transfer v-model="selectForm.type" @on-change='sentSelectFrom' placeholder='请选择土地状态'>
				<Option :key="_type.code" v-for="_type in typeList" :value="_type.code">{{_type.name}}</Option>
			</Select>
		</div>
		<div class="selectItem" style="width: 180px;">
			<Select size='large' transfer v-model="selectForm.useType" @on-change='sentSelectFrom' placeholder='请选择土地用途'
				multiple :max-tag-count='1'>
				<Option :key="_type.code" v-for="_type in useTypeList" :value="_type.code">{{_type.name}}</Option>
			</Select>
		</div>
	</div>
</template>

<script>
	import CitySelect from '../../../components/citySelect/index.vue'
	export default {
		name: 'selectBox',
		props: {
			ctiy: {
				type: Object,
				default: () => {
					return {}
				},
			},
		},
		components: {
			CitySelect
		},
		watch: {
			ctiy: {
				handler(val) {
					this.selectForm.city = val
				},
				immediate: true,
			}
		},
		data() {
			return {
				selectForm: {
					city: {},
					time: '2022-01',
					type: 2,
					useType: ['USE_RES', 'USE_COMS', 'USE_IND', 'USE_OTHER'],
				},
				time: '2022-01',
				typeList: [{
					name: '已成交',
					code: 2
				}, {
					name: '出让',
					code: 0
				}, {
					name: '流拍',
					code: 1
				}, ],
				//住宅、商服、工业、其他
				useTypeList: [{
					name: '住宅',
					code: 'USE_RES'
				}, {
					name: '商服',
					code: 'USE_COMS'
				}, {
					name: '工业',
					code: 'USE_IND'
				}, {
					name: '其他',
					code: 'USE_OTHER'
				}, ],
				options: {
					disabledDate(date) {
						return date && date.valueOf() < new Date('2011-1-1') || date.valueOf() > Date.now()
					}
				},
			}
		},
		created() {
			this.time = this.getLastMonth()
			this.selectForm.time = this.getLastMonth()
		},
		methods: {
			/*
			* 获取上一个月
			*/
			getLastMonth() {
				let date = new Date();
				let year = date.getFullYear();   //当前年：四位数字
				let month = date.getMonth();     //当前月：0-11

				if (month == 0) {   //如果是0，则说明是1月份，上一个月就是去年的12月
					year -= 1;
					month = 12;
				}

				month = month < 10 ? ('0' + month) : month;   //月份格式化：月份小于10则追加个0

				let lastYearMonth = year + '-' + month;

				return lastYearMonth;
			},
			selectTime(e) {
				this.selectForm.time = e
				this.$emit('selected', this.selectForm)
			},
			selectArea(city) {
				this.selectForm.city = city
				this.sentSelectFrom()
				this.$emit('selectedCity', city)
			},
			sentSelectFrom() {
				this.$emit('selected', this.selectForm)
			},
			closes() {
				this.$refs.CitySelect.handleClose()
			}
		},
	}
</script>

<style scoped lang="scss">
	.selectBox {
		display: inline-block;
		position: relative;
		font-size: 0;

		.selectItem {
			display: inline-block;
			vertical-align: middle;
			width: 125px;
			margin: 2px;
		}
	}
</style>
