var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"footerInfo"},[_c('InfoItem',{attrs:{"location":"left"}},[_c('div',{attrs:{"slot":"content"},slot:"content"},[_c('div',{staticClass:"_content",style:({ height: `${_vm.height * 0.6}px` }),on:{"wheel":function($event){$event.stopPropagation();}}},[_c('div',{staticClass:"title"},[_c('p',{staticClass:"titleInfo"},[_vm._v(_vm._s(_vm.data.title)+"排名")])]),_c('div',{staticClass:"tabbleBox",style:({ height: `${_vm.height * 0.6 - 90}px` }),on:{"wheel":function($event){$event.stopPropagation();}}},[_c('Table',{staticStyle:{"margin-top":"10px"},attrs:{"height":_vm.height * 0.6 - 90,"loading":_vm.dataLoading,"border":false,"columns":_vm.columns,"data":_vm.formData},on:{"on-sort-change":(res) => {
                  _vm.getSortValue(res);
                }},scopedSlots:_vm._u([{key:"name",fn:function({ row }){return [_c('Tooltip',{attrs:{"content":row.name,"placement":"bottom","max-width":_vm.widths,"transfer":true}},[_c('span',{staticClass:"ivu-table-cell-tooltip-content",style:({
                      width: `${_vm.widths - 20}px`,
                      color: row.lat == '' ? 'currentcolor' : '#1eaef5',
                      cursor: row.lat == '' ? 'default' : 'pointer',
                      'font-size': '14px',
                    }),on:{"click":function($event){return _vm.gocity(row.lat, row.lng, row.name)}}},[_vm._v(" "+_vm._s(row.name)+" ")])])]}}])})],1)])])])],1)
}
var staticRenderFns = []

export { render, staticRenderFns }