<template>
	<div class="legendInfo">
		<InfoItem location='right'>
			<div slot='content'>
				<div class="_content">
					<p class="title">城市等级</p>
					<p class="city city1">一线城市</p>
					<p class="city city2">二线城市</p>
					<p class="city city3">三线城市</p>
					<p class="city city4">四线及以下城市</p>
				</div>
			</div>
		</InfoItem>
	</div>
</template>

<script>
	// import InfoItem from './infoItem.vue'
	import InfoItem from '../../../components/infoItem.vue'
	export default {
		name: 'legendInfo',
		components: {
			InfoItem
		},
	}
</script>

<style scoped lang="scss">
	._content {
		height: 215px;
		font-size: 16px;
		padding: 20px;
	}
	.title{
		font-size: 16px;
		font-family: Source Han Sans CN, Source Han Sans CN-Medium;
		font-weight: 500;
		color: #747c8a;
	}
	.city{
		font-size: 14px;
		font-family: Source Han Sans CN, Source Han Sans CN-Regular;
		font-weight: 400;
		color: #747c8a;
		position: relative;
		margin: 10px 0 10px 20px;
		&:before{
			content: '';
			position: absolute;
			width: 16px;
			height: 16px;
			top: 2px;
			left: -25px;
		}
	}
	.city1{
		&:before{
			background-color: #1050A1;
		}
	}
	.city2{
		&:before{
			background-color: #0068CF;
		}
	}
	.city3{
		&:before{
			background-color: #2AA3EE;
		}
	}
	.city4{
		&:before{
			background-color: #71C2F4;
		}
	}
</style>
