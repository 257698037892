<template>
	<div class="topInfo">
		<InfoItem location='left'>
			<div slot='content'>
				<div class="_content">
					<div v-if="isTransaction" class="box detailBox">
						<div class="item">
							<div class="itemBox">
								<div class="detail">
									<span class="detailNum">{{numObj.number?numObj.number:'--'}}</span>宗
								</div>
								<div class="des">
									宗数
								</div>
							</div>
						</div>
						<div class="item">
							<div class="itemBox">
								<div class="detail">
									<span
										class="detailNum">{{numObj.floorPriceSum?numObj.floorPriceSum.toFixed(2):'--'}}</span>元/m²
								</div>
								<div class="des">
									楼面价
								</div>
							</div>

						</div>
						<div class="item">
							<div class="itemBox">
								<div class="detail">
									<span
										class="detailNum">{{numObj.transactionPriceSum?numObj.transactionPriceSum.toFixed(2):'--'}}</span>亿元
								</div>
								<div class="des">
									成交价
								</div>
							</div>
						</div>
					</div>


					<div v-if="!isTransaction" class="box detailBox">
						<div class="item">
							<div class="itemBox">
								<div class="detail">
									<span class="detailNum">{{numObj.number?numObj.number:'--'}}</span>宗
								</div>
								<div class="des">
									宗数
								</div>
							</div>
						</div>
						<div class="item">
							<div class="itemBox">
								<div class="detail">
									<span
										class="detailNum">{{numObj.landAreaSum?numObj.landAreaSum.toFixed(2):'--'}}</span>万m²
								</div>
								<div class="des">
									土地面积
								</div>
							</div>

						</div>

					</div>

					<div v-if="showNum" class="box numBox">
						<div v-if="hasOpt('USE_RES')" class="item">
							<div class="itemBox">
								<div class="detail">
									<span class="detailNum">{{numObj.useRes}}</span>宗
								</div>
								<div class="des">
									住宅
								</div>
							</div>
						</div>
						<div v-if="hasOpt('USE_COMS')" class="item">
							<div class="itemBox">
								<div class="detail">
									<span class="detailNum">{{numObj.useComs}}</span>宗
								</div>
								<div class="des">
									商服
								</div>
							</div>
						</div>
						<div v-if="hasOpt('USE_IND')" class="item">
							<div class="itemBox">
								<div class="detail">
									<span class="detailNum">{{numObj.useInd}}</span>宗
								</div>
								<div class="des">
									工业
								</div>
							</div>
						</div>
						<div v-if="hasOpt('USE_OTHER')" class="item">
							<div class="itemBox">
								<div class="detail">
									<span class="detailNum">{{numObj.useOther}}</span>宗
								</div>
								<div class="des">
									其他
								</div>
							</div>
						</div>
					</div>


				</div>
			</div>
		</InfoItem>
	</div>
</template>

<script>
	import InfoItem from '../../../components/infoItem.vue'
	import {
		land_data_map_overview_statistics
	} from '../../../../../api/reLand.js'
	export default {
		name: 'topInfo',
		data() {
			return {
				searchForm: {},
				timer: null,
				numObj: {},
			}
		},
		components: {
			InfoItem
		},
		computed: {
			showNum() {
				if (this.searchForm.hasOwnProperty('selected') && this.searchForm.selected.useType.length < 4) {
					return false
				} else {
					return true
				}
			},
			isTransaction() {
				if (this.searchForm.hasOwnProperty('selected') && this.searchForm.selected.type == 2) {
					return true
				} else {
					return false
				}
			}
		},
		props: {
			selectedOptions: {
				type: Object,
				default: () => {
					return {}
				}
			},
		},
		watch: {
			selectedOptions: {
				handler(val) {
					this.searchForm = JSON.parse(JSON.stringify(val))
					this.$nextTick(() => {
						this.getValue()
					})
				},
				immediate: true,
			}
		},
		methods: {
			async getValue() {
				if (this.timer) {
					clearTimeout(this.timer)
				}
				this.timer = setTimeout(async () => {
					if (this.searchForm.hasOwnProperty('landListAreaObject') && this.searchForm
						.landListAreaObject.province && this.searchForm
						.hasOwnProperty('selected') && this.searchForm.hasOwnProperty('showScope')) {
						let obj = this.searchForm
						let params = {}
						params.provinceName = obj.landListAreaObject.province
						params.cityName = obj.landListAreaObject.city
						params.districtName = obj.landListAreaObject.district
						params.month = obj.selected.time
						// params.transactionStateCodeList = [obj.selected.type]
						params.transactionStateCodeList = obj.selected.type == 0 ? [] : [obj.selected.type];
						params.zoom = obj.showScope.zoom
						params.landUseCodeList = obj.selected.useType.length != 0 ? obj.selected.useType :
							[],
							params.upperRightPoint = obj.showScope.NorthEast
						params.lowerLeftPoint = obj.showScope.SouthWest
						let data = await land_data_map_overview_statistics(params)
						this.numObj = data
					}
				}, 300)
			},
			hasOpt(key) {
				let swt = false
				this.searchForm.hasOwnProperty('selected') && this.searchForm.selected.useType.forEach(item => {
					if (item == key) {
						swt = true
					}
				})
				return swt
			},

		},

	}
</script>

<style scoped lang="scss">
	._content {
		height: 160px;
		font-size: 16px;
		padding: 16px;
		display: flex;
		flex-direction: column;
		justify-content: center;
	}

	.box {
		flex: 1;
		display: flex;
	}

	.detailBox {
		.item {
			flex: 1;
			text-align: center;

			.itemBox {
				height: 64px;
				position: relative;
				top: 50%;
				transform: translateY(-50%);

				.detail {
					.detailNum {
						font-size: 24px;
						font-family: Arial, Arial-Bold;
						font-weight: 700;
						color: #ec1303;
					}
				}

				.des {
					font-size: 14px;
					font-family: Source Han Sans CN, Source Han Sans CN-Regular;
					font-weight: 400;
					color: #999999;
				}
			}

		}
	}

	.numBox {
		.item {
			flex: 1;
			text-align: center;
			position: relative;

			.itemBox {
				width: 84px;
				height: 60px;
				display: inline-block;
				position: relative;
				top: 50%;
				transform: translateY(-50%);
				background: rgba($color: #dfecfe, $alpha: 0.6);
				border-radius: 8px;

				.detail {
					font-size: 12px;
					margin-top: 8px;

					.detailNum {
						font-size: 16px;
						font-family: Source Han Sans CN, Source Han Sans CN-Regular;
						font-weight: 400;
						color: #2965ff;
					}
				}

				.des {
					font-size: 12px;
					font-family: Source Han Sans CN, Source Han Sans CN-Regular;
					font-weight: 400;
					color: #333333;
				}
			}
		}
	}
</style>
