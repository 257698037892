<template>
  <div class="loading">
    <Spin >
      <div class="double"></div>
    </Spin>
  </div>
</template>
<script>
export default {};
</script>
<style scoped lang="scss">
.loading{
  position: absolute;
  z-index: 999;
  top: 50%;
  left: 50%;
}
.double {
  display: block;
  width: 30px;
  height: 30px;
  margin: 30px auto;
  position: relative;
  // animation: bg 2s infinite linear;
}
.double::before,
.double::after {
  content: "";
  display: block;
  width: 15px;
  height: 15px;
  border-radius: 50%;
  background: #98bff6;
  position: absolute;
  top: 5px;
}
.double::before {
  left: 0;
  animation: double_lt_position 1.5s infinite linear,
    double_lt_size 1.5s infinite linear, bg 1.5s infinite linear;
}
.double::after {
  left: 20px;
  animation: double_gt_position 1.5s infinite linear,
    double_gt_size 1.5s infinite linear, bg 1.5s infinite linear;
}
@keyframes bg {
  0% {
    background: #ff9900;
  }
  50% {
    background: #ed4014;
  }
  100% {
    background: #ff9900;
  }
}
@keyframes double_lt_position {
  50% {
    left: 20px;
  }
}
@keyframes double_lt_size {
  0% {
    transform: scale(1);
  }
  25% {
    transform: scale(0.5);
  }
  50% {
    transform: scale(1);
  }
  75% {
    transform: scale(1.5);
  }
  100% {
    transform: scale(1);
  }
}
@keyframes double_gt_position {
  50% {
    left: 0;
  }
}
@keyframes double_gt_size {
  0% {
    transform: scale(1);
  }
  25% {
    transform: scale(1.5);
  }
  50% {
    transform: scale(1);
  }
  75% {
    transform: scale(0.5);
  }
  100% {
    transform: scale(1);
  }
}
</style>